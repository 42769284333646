/*************************************
- Pop up notes
**************************************/

var popupNote = {};

popupNote.setBindings = function () {
    popupNote.icons.off("click.note").on("click.note", function () {
        var popupIcon = $(this).parent(),
            noteContainer = $(popupIcon.find("." + popupNote.containerClass));

        //popupNote.setPosition(popupIcon, noteContainer);
        popupNote.toggleActivate(popupIcon, noteContainer);
        popupNote.setPosition(popupIcon, noteContainer);
    });
};

popupNote.toggleIsHidden = function (noteContainer) {
    return (noteContainer.hasClass(popupNote.hiddenClass));
}

popupNote.toggleActivate = function (popupIcon, noteContainer) {
    if (popupNote.toggleIsHidden(noteContainer)) {
        popupNote.targetArray = [popupIcon, noteContainer];

        popupNote.hideAll();
        noteContainer.removeClass(popupNote.hiddenClass);
        popupIcon.addClass(popupNote.iconActiveClass);

        popupNote.bindDocument();
    } else {
        popupNote.targetArray[1].addClass(popupNote.hiddenClass);
        popupNote.targetArray[0].removeClass(popupNote.iconActiveClass);
    }
};

popupNote.bindDocument = function () {
    document.addEventListener("mousedown", popupNote.handleClick);
};

popupNote.handleClick = function (e) {
    e.stopImmediatePropagation();
    var target = e.target;

    if ($(target).hasClass(popupNote.iconClass)) {
        document.removeEventListener("mousedown", popupNote.handleClick);
        return;
    }

    if (
        !$(target)
            .parents()
            .hasClass(popupNote.containerClass) &&
        !$(target).hasClass(popupNote.containerClass)
    ) {
        document.removeEventListener("mousedown", popupNote.handleClick);
        popupNote.toggleActivate(
            popupNote.targetArray[0],
            popupNote.targetArray[1]
        );
    }
};

popupNote.hideAll = function () {
    var containers = $("." + popupNote.containerClass);
    for (var i = 0; i < containers.length; i++) {
        $(containers[i])
            .parent()
            .removeClass(popupNote.iconActiveClass);
        $(containers[i]).addClass(popupNote.hiddenClass);
    }
};

popupNote.setPosition = function (note, noteContainer) {
    if (popupNote.toggleIsHidden(noteContainer)) { return }//Exit if hidden

    //debugger;
    var btnRect = note[0].getBoundingClientRect();
    var btnHalf = (btnRect.width / 2);
    var btnHeight = btnRect.height;
    var btnCenter = btnRect.x + btnHalf;//Find center of button icon
    var btnBeforeWidth = parseInt(window.getComputedStyle(note[0], ':before').width.replace('px', ''));
    var btnBeforeWidthHalf = btnBeforeWidth / 2;
    var btnBeforeHeight = parseInt(window.getComputedStyle(note[0], ':before').height.replace('px', ''));

    var halfContainer = (noteContainer[0].scrollWidth / 2) + 1;
    if (halfContainer == 0) { (halfContainer = noteContainer[0].width / 2) + 1 };

    var containerLeft = btnCenter - halfContainer;
    var containerRight = btnCenter + halfContainer;

    noteContainer.css("top", btnHeight + btnBeforeHeight + "px");
    //window.getComputedStyle(note[0], ':before').setProperty("top", btnHeight + "px");

    // left side check
    if (containerLeft < 0) { noteContainer.css("left", (-btnRect.x) + "px"); }
    else if (containerRight > window.innerWidth) { noteContainer.css("left", ((-halfContainer * 2) + btnHalf + btnBeforeWidthHalf) + "px"); }
    else { noteContainer.css("left", -halfContainer + "px"); }

    ////var rect = note[0].getBoundingClientRect(),
    ////    //halfContainer = noteContainer[0].scrollWidth / 2;
    ////    halfContainer = noteContainer[0].width / 2;

    ////// left side check
    ////if (rect.x < halfContainer) {
    ////    noteContainer.css("left", -rect.x + 10 + "px");
    ////} else if (window.innerWidth - rect.x < halfContainer) {
    ////    var leftOver = window.innerWidth - rect.x;
    ////    var overAmount = halfContainer - leftOver;
    ////    var position = overAmount + halfContainer;

    ////    noteContainer.css("left", -position - 20 + "px");
    ////} else {
    ////    noteContainer.css("left", -halfContainer + 10 + "px");
    ////}
};

popupNote.init = function () {
    popupNote.containerClass = "popup-note-container";
    popupNote.hiddenClass = "hidden";
    popupNote.iconActiveClass = "active";
    popupNote.iconClass = "popup-note-icon";
    popupNote.icons = $("." + popupNote.iconClass);
    popupNote.hideAll();
    popupNote.setBindings();
};


$(function () {
    popupNote.init();
});

