/*
    accordions
    Version: 1.2 (verso)
    Author: Rory Saxby & Jake Upshall
    Dependencies: jQuery

    EXAMPLE:

    *** Initialise ***

    var generalAccordions = new logicjs.accordions({
        container: ".page-accordions",
        accordionsClass: ".general-accordion",
        accordionCtrlTarget: ".toggle-ctrl",
        hiddenClass: "hidden",
        accordionView: ".accordion-view",
        loadingClass: "loading"
    });

    *** Markup ***
    <div class="page-accordions">
        <div class="general-accordion">
            <button class="toggle-ctrl">Link 1</button>
            <div class="accordion-view">
                <!-- content here --> 
            </div>
        </div>
        <div class="general-accordion">
            <button class="toggle-ctrl">Link 2</button>
            <div class="accordion-view">
                <!-- content here -->
            </div>
        </div>
        <div class="general-accordion">
            <button class="toggle-ctrl">Link 3</button>
            <div class="accordion-view">
                <!-- content here -->
            </div>
        </div>
    </div>

 */

if (typeof (logicjs) === "undefined") var logicjs = {};

logicjs.accordions = function (opts) {
    this.container = $(opts.container);
    this.accordionsClass = opts.accordionsClass;
    this.accordionsPathClass = opts.accordionsPathClass != null ? opts.accordionsPathClass : " > ";
    this.accordions = this.container.find(this.accordionsPathClass + this.accordionsClass);
    this.accordionCtrlTarget = opts.accordionCtrlTarget;
    this.accordionCtrlTargets = this.container.find(opts.accordionCtrlTarget);
    this.accordionClickCallback = opts.accordionClickCallback != null ? opts.accordionClickCallback : false;
    this.hiddenClass = opts.hiddenClass != null ? opts.hiddenClass : "inactive";
    this.loadActiveClass = opts.loadActiveClass != null ? opts.loadActiveClass : "initially-opened";
    this.onLoadActiveCheckCallback = opts.onLoadActiveCheckCallback != null ? opts.onLoadActiveCheckCallback : false;
    this.accordionView = opts.accordionView;
    this.parentAccordionView = opts.parentAccordionView != null ? opts.parentAccordionView : null;
    this.loadingClass = opts.loadingClass != null ? opts.loadingClass : "loading";
    this.storeOpenCookie = opts.storeOpenCookie != null ? opts.storeOpenCookie : false; // {name:"", value:"", expires} || false;
    this.toggleAccordions = opts.toggleAccordions != null ? opts.toggleAccordions : false;
    this.toggleCallback = opts.toggleCallback != null ? opts.toggleCallback : false;
    this.setAccordionHeights = opts.setAccordionHeights != null ? opts.setAccordionHeights : true;
    this.disableAccordionClick = opts.disableAccordionClick != null ? opts.disableAccordionClick : false;
    this.loadHidden = opts.loadAccordionsHidden != null ? opts.loadAccordionsHidden : true;
    this.scrollToView = opts.scrollToView ? opts.scrollToView : false;
    this.scrollUnderClassList = opts.scrollUnderClassList ? opts.scrollUnderClassList : [];
    this.scrollTransitionDelay = opts.scrollTransitionDelay ? opts.scrollTransitionDelay : 200;
    this.scrollTime = opts.scrollTime ? opts.scrollTime : 200;

    this.setupAccordions();
};

logicjs.accordions.prototype.setupAccordions = function () {
    if (this.storeOpenCookie) this.getStoredCookie();
    this.container.addClass(this.loadingClass);
    this.bindAccordions();
    if (this.loadHidden) this.hideAll(this.disableCookieUpdate);
    this.container.removeClass(this.loadingClass);
};

logicjs.accordions.prototype.bindAccordions = function () {
    $(this.accordionCtrlTargets).off('click').on('click', function (e) {
        e.preventDefault();
        if (!this.disableAccordionClick) this.setActiveAccordion($(e.target).closest(this.accordionsClass));
    }.bind(this));
};

logicjs.accordions.prototype.setActiveAccordion = function (selected, disableCookieUpdate) {
    var expanded = false;
    if (selected.hasClass(this.hiddenClass)) {
        if (this.scrollToView) this.navigateToView(selected);
        if (this.toggleAccordions) this.hideAll(disableCookieUpdate);
        this.openSelectedView(selected);
        selected.removeClass(this.hiddenClass);
        expanded = true;
    } else {
        selected.addClass(this.hiddenClass);
        this.hideSelectedView(selected);
    }

    if (this.storeOpenCookie && !disableCookieUpdate) {
        this.updateStoredCookie(selected, expanded);
        this.updateCookie();
    }

    if (this.toggleCallback) this.toggleCallback();
};

logicjs.accordions.prototype.updateCookie = function () {
    var value = JSON.stringify(this.storedCookie);
    logicjs.setCookie(this.storeOpenCookie.name, value, 7);
};

logicjs.accordions.prototype.updateStoredCookie = function (accordion, expanded) {
    var name = accordion.find(this.accordionCtrlTarget).first().attr(this.storeOpenCookie.attr);
    if (expanded) {
        if (this.storedCookie.length > 0) {
            for (var i = 0; i < this.storedCookie.length; i++) {
                if (this.storedCookie[i] === name) return;
            }
        }
        this.storedCookie.push(name);
        return;
    }
    if (this.storedCookie.length == 0) return;
    for (var i = 0; i < this.storedCookie.length; i++) {
        if (this.storedCookie[i] === name) {
            this.storedCookie.splice(i, 1);
            return;
        }
    }
};

logicjs.accordions.prototype.openSelectedView = function (selected) {
    if (this.setAccordionHeights) {
        var view = selected.find(this.accordionView).first(),
            height = view[0].scrollHeight * 1.2;

        view[0].style.maxHeight = height + "px";
        this.setParentView(view, height);
    }
};

logicjs.accordions.prototype.setSelectedView = function (view, height) {
    view[0].style.maxHeight = height + "px";
};

logicjs.accordions.prototype.setParentView = function (view, height) {
    if (this.parentAccordionView !== null) {
        var parentView = $(view).parents(this.parentAccordionView).first();
        parentView[0].style.maxHeight = parentView[0].scrollHeight + height + "px";
    }
};

logicjs.accordions.prototype.hideAll = function (disableCookieUpdate) {
    for (var i = 0; i < this.accordions.length; i++) {
        var selected = $(this.accordions[i]);

        if (this.storeOpenCookie && !disableCookieUpdate) this.updateStoredCookie(selected, false);

        if (!selected.hasClass(this.loadActiveClass)) {
            this.hideSelectedView(selected);
        } else {
            selected.removeClass(this.loadActiveClass);

            var view = selected.find(this.accordionView),
                height = view[0].scrollHeight * 3;

            this.setSelectedView(view, height);
        }

    }
    this.updateCookie();
};

logicjs.accordions.prototype.hideSelectedView = function (selected) {
    selected.addClass(this.hiddenClass);
    if (this.setAccordionHeights) selected.find(this.accordionView)[0].style.maxHeight = 0;
};

logicjs.accordions.prototype.removeAllSetHeights = function () {
    for (var i = 0; i < this.accordions.length; i++) {
        var view = $(this.accordions[i]).find(this.accordionView).first();
        view.attr('style', '');
    }
};

logicjs.accordions.prototype.unset = function () {
    for (var i = 0; i < this.accordions.length; i++) {
        $(this.accordions[i]).find(this.accordionCtrlTarget).first().unbind('click');
        $(this.accordions[i]).removeClass(this.hiddenClass);
        $(this.accordions[i]).find(this.accordionView)[0].style = "";
    }
};

logicjs.accordions.prototype.getStoredCookie = function () {
    var value = logicjs.getCookie(this.storeOpenCookie.name);
    this.storedCookie = [];
    if (value !== "") this.storedCookie = JSON.parse(value);
};

logicjs.accordions.prototype.navigateToView = function (selected) {
    var _ = this;

    if (this.toggleAccordions) {

        for (var i = 0; i < this.accordions.length; i++) {

            if (!$(this.accordions[i]).hasClass(this.hiddenClass)) {

                this.activeAccordion = $(this.accordions[i]);

                setTimeout(function () {
                    //_.activeAccordion.off("transitionend").on("transitionend", function () {
                    _.animateToView(selected);
                    //});
                }, this.scrollTransitionDelay);
            }
            //else {
            //    $(this.accordions[i]).unbind("transitionend");
            //}
        }
    }
};

logicjs.accordions.prototype.animateToView = function (selected) {
    var fixedElementsVal = 0;

    if (this.scrollUnderClassList) {
        var item = [];
        var array = this.scrollUnderClassList;

        for (item of array) {
            if ($("." + item).length > 0) {
                fixedElementsVal += $("." + item).height();
            }
        }
    }

    $('html, body').animate({
        scrollTop: selected.offset().top - fixedElementsVal
    }, this.scrollTime);
};

/* Functions for external use */

logicjs.accordions.prototype.changeOpt = function (opt, value) {
    this[opt] = value;

    switch (opt) {
        case "toggleAccordions":
            if (value) {
                this.hideAll();
            }
            break;
        case "setAccordionHeights":
            if (value) {
                this.hideAll();
            } else {
                this.removeAllSetHeights();
            }
            break;
    }
};