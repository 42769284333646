/*************************************
- Site navigation dropdowns for mobile
**************************************/

$(function () {
    var siteDropNav = {};

    siteDropNav.targets = function () {
        siteDropNav.container = $("#SiteNavigation");
        siteDropNav.ddLinks = siteDropNav.container.find("li.has-sub-nav > a");
    };

    siteDropNav.bind = function () {
        siteDropNav.ddLinks.off("click").on("click", function () {
            siteDropNav.toggleClass($(this).parent());
        });
    };

    siteDropNav.toggleClass = function (ddContainer) {
        if (ddContainer.hasClass("active")) {
            ddContainer.removeClass("active");
        } else {
            ddContainer.addClass("active");
        }
    };

    siteDropNav.init = function () {
        siteDropNav.targets();
        siteDropNav.bind();
    };
});