var stickyTable = {};

stickyTable.targetTables = function () {
    for (var i = 0; i < stickyTable.containers.length; i++) {
        stickyTable.identifyTable($(stickyTable.containers[i]).find(stickyTable.tableClass));
    }
};

stickyTable.identifyTable = function (ele) {
    if (ele.hasClass(stickyTable.invertedTableVariationClassName)) {
        stickyTable.initInvertedTable(ele);
    } else {
        stickyTable.initTable(ele);
    }
};

// Inverted Table Fns

stickyTable.invGetHeaderHeights = function (ele) {
    var cells = ele.find(stickyTable.headClass + " " + stickyTable.cellClass),
        array = [];

    for (var i = 0; i < cells.length; i++) {
        array.push($(cells[i]).outerHeight());
    }

    return array;
};

stickyTable.invGetRowCellHeights = function (ele) {
    var body = ele.find(stickyTable.bodyClass),
        rows = body.find(stickyTable.rowClass),
        arrays = {};

    for (var i = 0; i < rows.length; i++) {
        arrays[i] = [];

        var cells = $(rows[i]).find(stickyTable.cellClass);

        for (var j = 0; j < cells.length; j++) {
            arrays[i].push($(cells[j]).outerHeight());
        }
    }

    return arrays;
};

stickyTable.invCompareHeights = function (headerHeights, rowHeights) {

    var array = [];

    for (var i = 0; i < headerHeights.length; i++) {
        var compareHeight = headerHeights[i],
            j;

        for (j in rowHeights) {

            if (rowHeights[j][i] > compareHeight) {
                compareHeight = rowHeights[j][i];
            }
        }

        array.push(compareHeight);
    }

    return array;
};

stickyTable.initInvertedTable = function (ele) {

    var headerHeights = stickyTable.invGetHeaderHeights(ele),
        rowHeights = stickyTable.invGetRowCellHeights(ele),
        selectedHeights = stickyTable.invCompareHeights(headerHeights, rowHeights);

    stickyTable.assignInvertedHeights(ele, selectedHeights);
};


stickyTable.assignInvertedHeights = function (ele, heights) {

    var rows = ele.find(stickyTable.rowClass);

    for (var i = 0; i < rows.length; i++) {
        var cells = $(rows[i]).find(stickyTable.cellClass);

        for (var j = 0; j < cells.length; j++) {
            $(cells[j]).css({
                'min-height': heights[j]
            });
        }
    }
};

// Default Table Fns

stickyTable.initTable = function (ele) {
    var rows = ele.find(stickyTable.rowClass);

    for (var i = 0; i < rows.length; i++) {
        var row = $(rows[i]),
            cells = row.find(stickyTable.cellClass);

        stickyTable.assignNormalHeight(row, cells);
    }
};

stickyTable.calcRowHeight = function (cells) {
    var cellHeight,
        maxCellHeight,
        val = [];

    for (var i = 0; i < cells.length; i++) {
        var cell = $(cells[i]);
        cellHeight = cell.innerHeight();
        val.push(cellHeight);
    }

    maxCellHeight = Math.max.apply(null, val);

    return maxCellHeight;
};

stickyTable.calcCellHeight = function (cells) {
    var row = cells.parent(stickyTable.rowClass),
        rowHeight = row.innerHeight();

    return rowHeight;
};

stickyTable.assignNormalHeight = function (row, cells) {
    row.css({
        "height": stickyTable.calcRowHeight(cells) + "px"
    });

    var stickyCells = row.find(stickyTable.cellStickyVariationClass);

    if (stickyCells) {

        stickyCells.css({
            "height": stickyTable.calcCellHeight(cells) + "px"
        });
    }
};

// End default table Fns

stickyTable.targetContainers = function () {
    if (stickyTable.containers.length > 0) {
        stickyTable.targetTables();
    }
};

stickyTable.unsetHeights = function () {
    var rows = stickyTable.containers.find(stickyTable.rowClass),
        cells = stickyTable.containers.find(stickyTable.cellClass);

    for (var i = 0; i < rows.length; i++) {
        $(rows[i]).css({
            'height': ''
        });
    }

    for (var j = 0; j < cells.length; j++) {
        $(cells[j]).css({
            'height': '',
            'min-height': ''
        });
    }
};

stickyTable.widthCheck = function () {

    if (window.matchMedia) {
        if (window.matchMedia("(min-width: 1024px)").matches) {
            stickyTable.targetContainers();
            stickyTable.heightsActive = true;
        } else {
            if (stickyTable.heightsActive) {
                stickyTable.unsetHeights();
                stickyTable.heightsActive = false;
            }
        }
    }
};

stickyTable.bindListeners = function () {
    window.addEventListener('resize', stickyTable.widthCheck);
};

stickyTable.init = function () {
    stickyTable.containersClass = ".c-table-form-container";
    stickyTable.tableClass = ".c-table-form";
    stickyTable.headClass = ".c-table-form__head";
    stickyTable.bodyClass = ".c-table-form__body";
    stickyTable.rowClass = ".c-table-form__row";
    stickyTable.cellClass = ".c-table-form__cell";
    stickyTable.cellStickyVariationClass = ".c-table-form__cell--sticky";
    stickyTable.invertedTableVariationClassName = "c-table-form--list-horizontal";

    stickyTable.containers = $(stickyTable.containersClass);
    if (stickyTable.containers.length > 0) {
        stickyTable.heightsActive = false;
        stickyTable.widthCheck();
        stickyTable.bindListeners();
    }

};
$(function () {
    stickyTable.init();
});