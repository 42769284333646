
$(document).ready(function () {


    if (document.getElementsByClassName("RadTimePicker").length > 0) timeChanger()

});


function timeChanger() {

    var test = $(".RadTimePicker").find("input[type=hidden]").val()
    var jsonString = JSON.parse(test)

    var minTime = jsonString.minDateStr.substr(jsonString.minDateStr.length - 8)
    var maxTime = jsonString.maxDateStr.substr(jsonString.maxDateStr.length - 8)

    var minTimeFormat = minTime.substring(0, minTime.length - 3).replace('-', '.')
    var maxTimeFormat = maxTime.substring(0, maxTime.length - 3).replace('-', '.')


    var table = $(".RadTimePicker").find(".RadCalendarTimeView").children()

    table.find("td").each(function () {

        var time = $(this).text().replace(':', '.')

        if (time != "undefined" & (time < minTimeFormat || time > maxTimeFormat)) {

            $(this).find('a').css("color", "red");
        }




    })


}
function rcbCancel(Sender, Args) {
    return false;
}

function rcbLoadOnDemand(sender, eventArgs) {
    //issue using keyboard to select an item in drop list. Throws a telerik error on enter (to select)

    var i = sender.get_selectedIndex() || 0; //most null - not sure how this is working
    if (eventArgs.get_domEvent().keyCode == 40) {
        //down arrow
        sender.set_selectedIndex = i + 1;
        return true;
    }
    if (eventArgs.get_domEvent().keyCode == 38) {
        //up arrow
        sender.set_selectedIndex = i - 1;
        return true;
    }

    var list = sender.get_attributes().getAttribute('data-list');
    var loadOnDemandInput = sender.get_text() + String.fromCharCode(eventArgs.get_domEvent().keyCode); // Picks up last key press 
    if (eventArgs.get_domEvent().keyCode == 40 || eventArgs.get_domEvent().keyCode == 38) { // ignore up or down arrows
        loadOnDemandInput = sender.get_text();
    }
    if (loadOnDemandInput.includes("..")) {
        return true;
    }
    //if (loadOnDemandInput.length < 3) {
    //    return true;
    //}
    //if (sender.get_dropDownVisible() && eventArgs.get_domEvent().keyCode != 9) {
    //    sender.hideDropDown();
    //}
    if (!sender.get_dropDownVisible() && eventArgs.get_domEvent().keyCode != 9) {
        sender.showDropDown();
    }
    sender.trackChanges();
    sender.clearItems();

    $.ajax({
        method: 'GET',
        url: '/online-application/endpoints/form/rcb-load-on-demand.ashx?fdListName=' + list + '&fdSearchTerm=' + loadOnDemandInput,
        success: function (r) {

            const response = JSON.parse(r);
            const loadOnDemandList = response.LoadOnDemandList;
            //var firstItem = true;
            loadOnDemandList.forEach((item) => {
                var comboItem = new Telerik.Web.UI.RadComboBoxItem();
                comboItem.set_text(item.Item);
                comboItem.set_value(item.Tag);
                // comboItem.select();
                sender.get_items().add(comboItem);
            });
            //sender.set_selectedIndex = 0;
            //var maxItems = 5;

            //$('.rcbScroll.rcbWidth').height((sender.get_items().get_count() * 40) + 5);
            //$('.rcbScroll.rcbWidth').height('100%');
            //$('.rcbScroll.rcbWidth').maxHeight(205);
        },
        error: function (e) {
            console.error(e);
        }
    });
    sender._resizeDropDown()
    sender.commitChanges();

}
