/***************************
- rtTabList 
****************************/

var rtTabList = function (opts) {
    this.container = $(opts.container);
    this.listUl = this.container.find(opts.listUl);
    this.listItems = this.listUl.find(opts.listItems);
    this.menuDisplayClass = opts.menuDisplayClass;
    this.disableAnimClass = opts.disableAnimClass;
    this.listUl.addClass(this.disableAnimClass);

    this.init();
};

rtTabList.prototype.addMenuButton = function () {
    var button =
        '<div class="tab-list-btn-container"><span>Selected:</span><button class="tab-list-menu-btn" type="button"></button></div>';
    this.container.prepend(button);
    this.buttonCtrl = this.container.find(".tab-list-menu-btn");
};

rtTabList.prototype.bindMenuButton = function () {
    var _ = this;
    this.buttonCtrl.off("click.listbutton").on("click.listbutton", function (e) {
        e.preventDefault();
        _.toggleMenu();
    });
};

rtTabList.prototype.updateButtonText = function (text) {
    this.buttonCtrl[0].innerText = text;
};

rtTabList.prototype.bindListItems = function () {
    var _ = this;

    this.listItems.on("click", function () {
        _.updateButtonText($(this).find(".rtsTxt")[0].innerText);
        _.hideMenu();
    });
};

rtTabList.prototype.hideMenu = function () {
    this.listUl.addClass(this.menuDisplayClass);
    this.buttonCtrl.removeClass("active");
};

rtTabList.prototype.showMenu = function () {
    this.listUl.removeClass(this.menuDisplayClass);
    this.buttonCtrl.addClass("active");
};

rtTabList.prototype.toggleMenu = function () {
    if (this.listUl.hasClass(this.menuDisplayClass)) {
        this.showMenu();
    } else {
        this.hideMenu();
    }
};

rtTabList.prototype.loadFirstTabText = function () {
    for (var i = 0; i < this.listItems.length; i++) {
        if ($(this.listItems[i]).hasClass("rtsSelected")) {
            this.updateButtonText($(this.listItems[i]).find(".rtsTxt")[0].innerText);
        }
    }
};

rtTabList.prototype.init = function () {
    this.addMenuButton();
    this.bindMenuButton();
    this.bindListItems();
    this.loadFirstTabText();

    this.hideMenu();
};

/***************************
- rtTabList menus
****************************/

var tabListMenu = {};

tabListMenu.init = function () {
    var tabMenuOne = new rtTabList({
        container: "#pvApplications",
        listUl: ".rtsUL",
        listItems: "> li",
        menuDisplayClass: "list-hidden",
        disableAnimClass: "disable-animation"
    });
    var tabMenuTwo = new rtTabList({
        container: "#pvProfile",
        listUl: ".rtsUL",
        listItems: "> li",
        menuDisplayClass: "list-hidden",
        disableAnimClass: "disable-animation"
    });
};