/***************************
- Repeater Accordions
****************************/
var repeaterAccs = {};

repeaterAccs.getAccordionNameSetting = function (ele) {
    var dataName = ele.attributes.getNamedItem("data-accordion-name");

    if (dataName) {
        return dataName.value;
    }
    return false;
};

repeaterAccs.getToggleSetting = function (ele) {
    var dataToggle = ele.attributes.getNamedItem("data-accordion-toggle");

    if (dataToggle) {
        return JSON.parse(dataToggle.value);
    }
    return false;
};

repeaterAccs.init = function () {

    repeaterAccs.repeaters = document.getElementsByClassName("c-fieldset-accordions");
    if (repeaterAccs.repeaters) {

        repeaterAccs.accordionSet = [];

        for (var i = 0; i < repeaterAccs.repeaters.length; i++) {
            repeaterAccs.accordionSet[i] = new logicjs.accordions({
                container: repeaterAccs.repeaters[i],
                accordionsPathClass: " > div > ",
                accordionsClass: ".c-fieldset-accordions__item",
                accordionCtrlTarget: ".c-fieldset-accordions__toggle",
                hiddenClass: "c-fieldset-accordions__item--hidden",
                accordionView: ".c-fieldset-accordions__view",
                storeOpenCookie: {
                    name: repeaterAccs.getAccordionNameSetting(repeaterAccs.repeaters[i]) || "ApplicationFieldsetAccordionExpanded",
                    attr: "data-accordion-row-id"
                },
                loadingClass: "loading",
                toggleAccordions: repeaterAccs.getToggleSetting(repeaterAccs.repeaters[i]),
                loadAccordionsHidden: true,
                setAccordionHeights: true
            });
        }
    }
};

$(function () {
    repeaterAccs.init();
});