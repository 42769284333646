/*** Welcome to logicjs ***
    As this is the only required file, keep it clean by only including neccessary functions.
    Create a new file for new features e.g logicjs.exampleFeature = function(){}. From here create your prototype functions.
*/

if (typeof (logicjs) === "undefined") var logicjs = {};

/*** Touch screen device check ***
    returns true if is touchscreen device
*/

logicjs.isTouchDevice = function () {
    return 'ontouchstart' in window // works on most browsers 
        ||
        navigator.maxTouchPoints; // works on IE10/11 and Surface
};

/*** Browser Check ***
    Returns browser associated values (eg -webkit-)
*/

logicjs.browserKit = function () {
    var styles = window.getComputedStyle(document.documentElement, ''),
        pre = (Array.prototype.slice
            .call(styles)
            .join('')
            .match(/-(moz|webkit|ms)-/) || (styles.OLink === '' && ['', 'o'])
        )[1],
        dom = ('WebKit|Moz|MS|O').match(new RegExp('(' + pre + ')', 'i'))[1];
    return {
        dom: dom,
        lowercase: pre,
        css: '-' + pre + '-',
        js: pre[0].toUpperCase() + pre.substr(1)
    };
};

/*** Cookie script ***
    set cookie - sets cookie values passed in: "cookie-name" : string, "cookie value" : string, 500 : number
    get cookie - returns cookie value
*/

logicjs.setCookie = function (cname, cvalue, exdays) {
    var d = new Date();
    d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
    var expires = "expires=" + d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

logicjs.getCookie = function (cname) {
    var name = cname + "=",
        ca = document.cookie.split(';');
    for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
}