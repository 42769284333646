/***************************
- Popout Overlay
****************************/

var popoutOverlay = function (opts) {
    this.containersClass = opts.containersClass;
    this.containers = $(opts.containers);
    this.innerContainerClass = opts.innerContainerClass != null ? opts.innerContainerClass : null;
    this.overflowVariationClass = opts.overflowVariationClass != null ? opts.overflowVariationClass : null;
    this.activeClass = opts.activeContainerClass != null ? opts.activeContainerClass : "popup-container--open";
    this.hiddenClass = opts.hiddenButtonClass != null ? opts.hiddenButtonClass : "initially-hidden";
    this.btnOuterCloseClass = opts.outerCloseButtonClass;
    this.btnLaunchClass = opts.launchButtonClass;
    this.btnCloseClass = opts.closeButtonClass;
    this.closePopoutBP = opts.closePopoutBP != null ? opts.closePopoutBP : null;

    this.bindAction();
};

popoutOverlay.prototype.bindAction = function () {

    if (this.containers.find("." + this.btnLaunchClass)) {
        var _ = this;

        this.btnOpen = this.containers.find("." + this.btnLaunchClass);
        this.btnClose = this.containers.find("." + this.btnCloseClass);
        this.btnOuterClose = this.containers.find("." + this.btnOuterCloseClass);

        this.btnOpen.off("click").on("click", function () {
            _.openPopout();
            _.addOverflowClass();

            if (_.closePopoutBP) {
                window.addEventListener("resize", _.checkBrowserWidth.bind(_));
            }
        });

        this.btnClose.off("click").on("click", function () {
            _.closePopout();
            _.removeOverflowClass();
            window.removeEventListener("resize", _.checkBrowserWidth);
        });

        this.btnOuterClose.off("click").on("click", function () {
            _.closePopout();
            _.removeOverflowClass();
            window.removeEventListener("resize", _.checkBrowserWidth);
        });

        this.containers.off("click").on("click", function (e) {
            if ($(e.target).hasClass(_.activeClass)) {
                _.closePopout();
                _.removeOverflowClass();
                window.removeEventListener("resize", _.checkBrowserWidth);
            }
        });
    }
};

popoutOverlay.prototype.checkBrowserWidth = function () {
    if (window.matchMedia) {

        if (window.matchMedia("(max-width:" + this.closePopoutBP + "px)").matches) {
            this.closePopout();
            this.removeOverflowClass();
        }
    }
};

popoutOverlay.prototype.addOverflowClass = function () {
    var _ = this;

    if (_.overflowVariationClass) {
        _.getOverlaySettings();
        window.addEventListener('resize', _.getOverlaySettings.bind(_));
    }
};

popoutOverlay.prototype.removeOverflowClass = function () {
    var _ = this;

    if (this.overflowVariationClass) {
        this.containers.removeClass(this.overflowVariationClass);
        window.removeEventListener('resize', _.getOverlaySettings);
    }
};

popoutOverlay.prototype.openPopout = function () {
    this.btnOpen.addClass(this.hiddenClass);
    this.btnOuterClose.removeClass(this.hiddenClass);
    this.btnClose.removeClass(this.hiddenClass);
    if (!this.containers.hasClass(this.activeClass)) this.containers.addClass(this.activeClass);
};

popoutOverlay.prototype.closePopout = function () {
    this.btnOuterClose.addClass(this.hiddenClass);
    this.btnClose.addClass(this.hiddenClass);
    this.btnOpen.removeClass(this.hiddenClass);
    this.containers.removeClass(this.activeClass);
};

popoutOverlay.prototype.getOverlaySettings = function () {
    var overlayTarget = document.getElementsByClassName(this.containersClass)[0].getElementsByClassName(this.innerContainerClass)[0];

    var overlayTopPos = overlayTarget.getBoundingClientRect().top;
    var overlayDisplayHeight = overlayTarget.parentNode.getBoundingClientRect().bottom;
    var overlayBottomPos = overlayDisplayHeight - overlayTarget.getBoundingClientRect().bottom;

    this.checkOverlayPosition(overlayTopPos, overlayBottomPos);
};

popoutOverlay.prototype.checkOverlayPosition = function (topPos, bottomPos) {
    if (topPos <= 0 && bottomPos <= 0) {
        this.containers.addClass(this.overflowVariationClass);
    } else {
        this.containers.removeClass(this.overflowVariationClass);
    }
};





var popoutFeature = function () {
    var initPopout = function () {
        var popoutSet = [];

        for (var i = 0; i < repeaters.length; i++) {
            popoutSet[i] = new popoutOverlay({
                containersClass: popoutContainerClass,
                containers: repeaters[i],
                innerContainerClass: "c-popout-container__inner",
                overflowVariationClass: "c-popout-container--overflow",
                activeContainerClass: "c-popout-container--open",
                hiddenButtonClass: "initially-hidden",
                launchButtonClass: "c-popout-container__btn-launch",
                closeButtonClass: "c-popout-container__btn-close",
                outerCloseButtonClass: "c-popout-container__btn-outer-close",
                closePopoutBP: 767
            });
        }
    };

    var popoutContainerClass = "c-popout-container";
    var repeaters = document.getElementsByClassName(popoutContainerClass);
    if (repeaters) initPopout();
};

var popoutFeature2 = function () {
    var initPopout = function () {
        var popoutSet = [];

        for (var i = 0; i < repeaters.length; i++) {
            popoutSet[i] = new popoutOverlay({
                containersClass: popoutContainerClass,
                containers: repeaters[i],
                innerContainerClass: "c-table-form-container__outer",
                overflowVariationClass: "c-table-form-container--overflow",
                activeContainerClass: "c-table-form-container--popout",
                hiddenButtonClass: "initially-hidden",
                launchButtonClass: "c-table-form__btn-launch",
                closeButtonClass: "c-table-form__btn-close",
                outerCloseButtonClass: "c-table-form-container__btn-close",
                closePopoutBP: 1023
            });
        }
    };

    var popoutContainerClass = "c-table-form-container";
    var repeaters = document.getElementsByClassName(popoutContainerClass);
    if (repeaters) initPopout();
};

$(function () {
    popoutFeature();
    popoutFeature2();
});