if (!SIDAction) var SIDAction = {};

//Click+ (Result Types)
//Ctrl        : Short Default Action(Input -> .Input.TextBox("sid",) or Get -> .Find("sid")
//Alt         : Short Get -> .Find("sid")
//Shift       : SID only -> "sid"
//Ctrl + Shift: Long Default Action(Input -> Driver.Input.TextBox("sid",) or Get -> Driver.Find("sid")
//Alt + Shift : Long Get -> Driver.Get("sid")

SIDAction.getKeyField = function (codeType, SID) {

    switch (codeType) {

        //UIFields - Readonly
        case 'field-readonly': SIDAction.processKey(['.Field("{0}").ReadOnly()', '.Get.Form.Field("{0}")', 'SEApplication'], SID, false);
            break;

        case 'field-number-readonly': SIDAction.processKey(['.Field("{0}").ReadOnly()', '.Get.Form.Field("{0}")', 'SEApplication'], SID, false);
            break;

        case 'field-date-readonly': SIDAction.processKey(['.Field("{0}").ReadOnly()', '.Get.Form.Field("{0}")', 'SEApplication'], SID, false);
            break;

        case 'field-time-readonly': SIDAction.processKey(['.Field("{0}").ReadOnly()', '.Get.Form.Field("{0}")', 'SEApplication'], SID, false);
            break;


        //UIFields
        case 'field': SIDAction.processKey(['.Field("{0}").Input()', '.Field("{0}")', 'SEApplication'], SID, false);
            break;

        case 'field-number': SIDAction.processKey(['.Field("{0}").Input()', '.Field("{0}")', 'SEApplication'], SID, false);
            break;

        case 'field-date': SIDAction.processKey(['.Field("{0}").Input(New Date())', '.Field("{0}")', 'SEApplication'], SID, false);
            break;

        case 'field-time': SIDAction.processKey(['.Field("{0}").Input(New TimeSpan())', '.Field("{0}")', 'SEApplication'], SID, false);
            break;


        //Misc
        case 'state-button': SIDAction.processKey(['.Click_Action("{0}")', 'SEApplication.Click_Action("{0}").Then.Alert_Click_Ok()', 'SEApplication'], SID, false);
            break;

        case 'field-button': SIDAction.processKey(['.Field("{0}").Click()', '.Field("{0}")', 'SEApplication'], SID, false);
            break;

        case 'field-repeater': SIDAction.processKey(['.Field("{0}").Add_Row()', '.Field("{0}")', 'SEApplication'], SID, false);
            break;

        case 'field-repeater-row': SIDAction.processKey(['.Field("{0}").Remove_Row()', '.Field("{0}")', 'SEApplication'], SID, false);
            break;

        case 'field-group': SIDAction.processKey(['.Field("{0}")', '.Get.Form.Field("{0}")', 'SEApplication'], SID, false);
            break;

        case 'sign-off-user': SIDAction.processKey(['.SignOff.User({0})', 'SEApplication.SignOff.User({0})', 'SEApplication'], SID, false);
            break;

        case 'sign-off-user-name': SIDAction.processKey(['.SignOff.User({0}).Name', '.SignOff.User({0})', 'SEApplication'], SID, false);
            break;

        case 'sign-off-user-email': SIDAction.processKey(['.SignOff.User({0}).Email', '.SignOff.User({0})', 'SEApplication'], SID, false);
            break;

        case 'sign-off-user-address': SIDAction.processKey(['.SignOff.User({0}).Address', '.SignOff.User({0})', 'SEApplication'], SID, false);
            break;

        case 'sign-off-user-phone': SIDAction.processKey(['.SignOff.User({0}).Phone', '.SignOff.User({0})', 'SEApplication'], SID, false);
            break;

        case 'date_tag': SIDAction.processKey(['.Get.Text("{0}")', '.Find("{0}")', 'Driver'], SID, false);
            break;

        case 'data_tag': SIDAction.processKey(['.Get.Text("{0}")', '.Find("{0}")', 'Driver'], SID, false);
            break;

        case 'status_tag': SIDAction.processKey(['.Get.Text("{0}")', '.Get.Element.Attribute_Value("{0}", "")', 'Driver'], SID, false);
            break;

        case 'form_answer': SIDAction.processKey(['.Answer("{0}", "")', '.Answer("{0}")', 'SEApplication'], SID, false);
            break;

        default: SIDAction.processKey(['.Field("{0}").Input("")', '.Field("{0}")', 'Driver.Verso'], SID, false);
    }
}

