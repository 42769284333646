/* Config */

/***************************
-Fix Scroll
****************************/

var fixedScrollAlt = function (opts) {
    this.opts = {};

    this.opts.ele = $(opts.fixedElement) || false;
    this.opts.adjEle = $(opts.adjacentElement) || false;
    this.opts.fixedClass = opts.fixedClass || false;
    this.opts.absoluteClass = opts.absoluteClass || false;
    this.opts.prevScroll = opts.prevScroll || 0;
    this.opts.enableWidth = opts.enableWidth || null;
    this.opts.fixAlt = opts.fixAlt || true;

    if (this.checkOpts()) {
        this.init();
    }
};

fixedScrollAlt.prototype.checkOpts = function () {
    if (
        !this.opts.ele ||
        !this.opts.adjEle ||
        !this.opts.fixedClass ||
        !this.opts.absoluteClass
    ) {
        console.error("fixedScrollAlt: Missing required initialise property");
        return false;
    }
    return true;
};

/***** Start Alt *****/

fixedScrollAlt.prototype.identifyAlternateState = function () {
    if (
        this.opts.wScrollTop < this.opts.adjEleTop ||
        this.opts.eleHeight > this.opts.adjEleHeight
    ) {
        this.unsetAll();
    } else {
        switch (this.opts.wScrollDir) {
            case "up":
                this.identifyAlternateStateUp();
                break;
            case "down":
                this.identifyAlternateStateDown();
                break;
        }
    }

    this.opts.prevScroll = this.opts.wScrollTop;
};

fixedScrollAlt.prototype.identifyAlternateStateUp = function () {
    if (this.opts.wScrollTop - 1 > this.opts.eleTop) {
        this.unsetFixed();
        this.fixAbsolute(this.opts.eleOffsetContainer);
        return;
    }

    if (
        this.opts.wScrollTop <= this.opts.eleTop &&
        this.opts.wScrollTop > this.opts.adjEleTop
    ) {
        this.setFixed();
        this.attachTop();
        this.unsetAbsolute();
        return;
    }
};

fixedScrollAlt.prototype.identifyAlternateStateDown = function () {
    if (
        this.opts.wScrollBottom >= this.opts.eleBottom &&
        this.opts.wScrollBottom < this.opts.adjEleBottom
    ) {
        // fix window to bottom
        this.setFixed();
        this.attachBottom();
        this.unsetAbsolute();
        return;
    }

    if (
        this.opts.wScrollBottom < this.opts.eleBottom ||
        this.opts.wScrollBottom >= this.opts.adjEleBottom
    ) {
        this.unsetFixed();
        this.fixAbsolute(this.opts.eleOffsetContainer);
        return;
    }
};

/***** End Alt *****/

/***** Start Fixed *****/

fixedScrollAlt.prototype.identifyFixedState = function () {
    switch (this.opts.wScrollDir) {
        case "up":
            this.identifyFixedStateUp();
            break;
        case "down":
            this.identifyFixedStateDown();
            break;
    }

    this.opts.prevScroll = this.opts.wScrollTop;
};

fixedScrollAlt.prototype.identifyFixedStateUp = function () {
    if (this.opts.wScrollTop < this.opts.adjEleTop) {
        this.unsetFixed();
        this.unsetAbsolute();
        this.clearInlineStyles();
        return;
    }

    if (this.opts.wScrollTop <= this.opts.eleTop) {
        this.setFixed();
        this.attachTop();
        this.unsetAbsolute();
        return;
    }

    if (this.opts.wScrollTop - 1 > this.opts.eleTop) {
        this.unsetFixed();
        this.fixAbsolute(this.opts.eleOffsetMax);
        return;
    }
};

fixedScrollAlt.prototype.identifyFixedStateDown = function () {
    if (this.opts.eleBottom >= this.opts.adjEleBottom) {
        this.unsetFixed();
        this.fixAbsolute(this.opts.eleOffsetMax);
        return;
    }

    if (this.opts.wScrollTop > this.opts.eleTop) {
        this.setFixed();
        this.attachTop();
        this.unsetAbsolute();
        return;
    }
};

/***** End Fixed *****/

fixedScrollAlt.prototype.unsetAll = function () {
    this.unsetFixed();
    this.unsetAbsolute();
    this.clearInlineStyles();
};

fixedScrollAlt.prototype.attachBottom = function () {
    this.opts.ele.css("bottom", "0");
    this.opts.ele.css("top", "auto");
};

fixedScrollAlt.prototype.attachTop = function () {
    this.opts.ele.css("top", "0");
    this.opts.ele.css("bottom", "auto");
};

fixedScrollAlt.prototype.setFixed = function () {
    this.opts.ele.addClass(this.opts.fixedClass);
};

fixedScrollAlt.prototype.fixAbsolute = function (calc) {
    this.opts.ele.addClass(this.opts.absoluteClass);
    this.opts.ele.css("top", calc + "px");
    this.opts.ele.css("bottom", "auto");
};

fixedScrollAlt.prototype.unsetFixed = function () {
    this.opts.ele.removeClass(this.opts.fixedClass);
};

fixedScrollAlt.prototype.unsetAbsolute = function () {
    this.opts.ele.removeClass(this.opts.absoluteClass);
};

fixedScrollAlt.prototype.clearInlineStyles = function () {
    this.opts.ele.css({
        bottom: "",
        top: ""
    });
};

fixedScrollAlt.prototype.scrollDir = function () {
    if (this.opts.wScrollTop > this.opts.prevScroll) return "down";
    if (this.opts.wScrollTop < this.opts.prevScroll) return "up";
    if (this.opts.wScrollTop == this.opts.prevScroll) return "same";
};

fixedScrollAlt.prototype.compareColElements = function () {
    if (this.opts.fixAlt) {
        if (
            this.opts.eleHeight > this.opts.wHeight
            //   && this.opts.adjEleHeight > this.opts.wHeight
        ) {
            return "fix alternate";
        }
    }

    if (
        this.opts.eleHeight < this.opts.wHeight
        // && this.opts.adjEleHeight > this.opts.wHeight
    ) {
        return "fix top";
    }
    return "unset";
};

fixedScrollAlt.prototype.setCalcs = function () {
    // Element
    this.opts.eleHeight = this.opts.ele[0].scrollHeight;
    this.opts.eleTop = Math.floor(this.opts.ele.offset().top);
    this.opts.eleBottom = this.opts.eleTop + this.opts.eleHeight;
    this.opts.eleOffsetContainer =
        this.opts.eleTop - Math.floor(this.opts.ele.parent().offset().top);
    // Adjacent element
    this.opts.adjEleHeight = this.opts.adjEle[0].scrollHeight;
    this.opts.adjEleTop = Math.floor(this.opts.adjEle.offset().top);
    this.opts.adjEleBottom = this.opts.adjEleTop + this.opts.adjEleHeight;

    this.opts.eleOffsetMax = this.opts.adjEleHeight - this.opts.eleHeight;

    // Window
    this.opts.wHeight = window.innerHeight;
    this.opts.wScrollTop = window.pageYOffset;
    this.opts.wScrollBottom = this.opts.wHeight + this.opts.wScrollTop;
};

fixedScrollAlt.prototype.setInitFns = function (outerFn) {
    this.setCalcs();

    if (!outerFn) {
        this.opts.wScrollDir = this.scrollDir();
    }

    switch (this.compareColElements()) {
        case "fix top":
            this.identifyFixedState();
            break;
        case "fix alternate":
            this.identifyAlternateState();
            break;
        case "unset":
            this.unsetAll();
            break;
    }
};

fixedScrollAlt.prototype.checkContainerHeights = function () {
    if (this.opts.ele.outerHeight() < this.opts.adjEle.outerHeight()) {
        this.setInitFns();
    } else {
        this.unsetAll();
    }
};

fixedScrollAlt.prototype.setListeners = function () {
    var _ = this;

    window.addEventListener("scroll", function () {
        if (window.matchMedia("(min-width:" + _.opts.enableWidth + "px)").matches) {
            _.checkContainerHeights();
        }
    });

    if (this.opts.enableWidth) {
        window.addEventListener("resize", function () {
            if (
                window.matchMedia("(min-width:" + _.opts.enableWidth + "px)").matches
            ) {
                _.checkContainerHeights();
            } else {
                _.unsetAll();
            }
        });
    }
};

fixedScrollAlt.prototype.init = function () {
    this.checkContainerHeights();
    this.setListeners();
};

fixedScrollAlt.prototype.changeOpt = function (opt, value) {
    this.opts[opt] = value;
};

/***************************
-Init Fix Scroll
****************************/

$(function () {
    if ($(".sidebar").length > 0) {
        sideNavFn();
    }
});

var sideNavFn = function () {
    var sideNavScroll = new fixedScrollAlt({
        fixedElement: ".sidebar",
        adjacentElement: ".content-gutter",
        fixedClass: "sidebar--fixed",
        absoluteClass: "sidebar--absolute",
        enableWidth: 768
    });
};

